import React, { useEffect } from 'react'
import long_arrow from "../../../assets/images/sec_before_style.png";
import { Link } from 'react-router-dom';

const PrivacyPolicy = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);
    return (
        <section className='terms-policy pt-20 mb-10'>
            <div className="theme__container">
                <div className="meetour__arrow mt-16">
                    <img src={long_arrow} alt="" />
                </div>
                <h1
                    className="section-header text-6xl"
                    data-aos="fade-right"
                    data-aos-duration="2000"
                >
                    Privacy Policy
                </h1>
                <p className='text-md text-white-60 opacity-75 text-center mb-8 -mt-6'>Last Updated: 01st March 2024</p>
                <p className='text-md text-white-60 mb-3'>
                    Thank you for visiting uhusolutions.com. At UHU Solutions, we are committed to protecting the privacy and security of your personal information. This Privacy Policy outlines the types of information we collect, how we use it, and the choices you have regarding your information.
                </p>
                <h2 className="font-semibold text-white text-2xl leading-relaxed my-4">Information We Collect</h2>
                <ul className='list-disc pl-4'>
                    <li className='text-md text-white-60 mb-3'>
                        <span className='font-semibold text-white text-1.5xl leading-relaxed'>Personal Information:</span> When you visit our Website, we may collect personal information such as your name, email address, phone number, and any other information you provide voluntarily.
                    </li>
                    <li className='text-md text-white-60 mb-3'>
                        <span className='font-semibold text-white text-1.5xl leading-relaxed'>Automatically Collected Information:</span> We may also collect certain information automatically, including your IP address, browser type, operating system, and browsing behavior on our Website.
                    </li>
                </ul>
                <h2 className="font-semibold text-white text-2xl leading-relaxed my-4">How We Use Your Information</h2>
                <p className="text-md text-white-60 mb-3">
                    We may use the information we collect for the following purposes:
                </p>
                <ul className='list-disc pl-4'>
                    <li className='text-md text-white-60 mb-3'>
                        To provide and improve our services.
                    </li>
                    <li className='text-md text-white-60 mb-3'>
                        To respond to your inquiries and communicate with you.
                    </li>
                    <li className='text-md text-white-60 mb-3'>
                        To personalize your experience on our Website.
                    </li>
                    <li className='text-md text-white-60 mb-3'>
                        To detect, prevent, and address technical issues.
                    </li>
                    <li className='text-md text-white-60 mb-3'>
                        To comply with legal obligations.
                    </li>
                </ul>
                <h2 className="font-semibold text-white text-2xl leading-relaxed my-4">Cookies</h2>
                <p className="text-md text-white-60 mb-3">
                    We may use cookies and similar tracking technologies to track the activity on our Website and hold certain information. You have the option to accept or refuse these cookies and know when a cookie is being sent to your computer. If you choose to refuse our cookies, you may not be able to use some portions of our Website.
                </p>
                <h2 className="font-semibold text-white text-2xl leading-relaxed my-4">Third-Party Services</h2>
                <p className="text-md text-white-60 mb-3">
                    We may use third-party services for various purposes, such as analytics, advertising, and payment processing. These third-party service providers have their privacy policies addressing how they use such information.
                </p>
                <h2 className="font-semibold text-white text-2xl leading-relaxed my-4">Data Security</h2>
                <p className="text-md text-white-60 mb-3">
                    We take reasonable precautions to protect your personal information from unauthorized access, use, or disclosure. However, no method of transmission over the Internet or electronic storage is 100% secure, and we cannot guarantee absolute security.
                </p>
                <h2 className="font-semibold text-white text-2xl leading-relaxed my-4">Changes to This Privacy Policy</h2>
                <p className="text-md text-white-60 mb-3">
                    We may update our Privacy Policy from time to time. Any changes will be posted on this page, and the "Last Updated" date will be revised accordingly. We encourage you to review this Privacy Policy periodically for any updates.
                </p>
                <h2 className="font-semibold text-white text-2xl leading-relaxed my-4">Contact Us</h2>
                <p className="text-md text-white-60 mb-3">
                    If you have any questions or concerns about our Privacy Policy, please contact us at <Link to="mailto:info@uhusolutions.com" className="blue-clr">info@uhusolutions.com</Link>
                </p>
            </div>
        </section>
    )
}

export default PrivacyPolicy