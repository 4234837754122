import {  React, useEffect, useState} from "react";
import "./contactUs.scss";
import { Link } from "react-router-dom";
import long_arrow from "../../../assets/images/sec_before_style.png";
import call from "../../../assets/images/call.png";
import email from "../../../assets/images/email.png";
import adress_icon from "../../../assets/images/address-icon.png";
import ContactInput from "../../common/ContactInput/ContactInput";


const ContactUs = (props) => {
    useEffect(() => {
      window.scrollTo(0, 0)
    }, []);
    const [values, setValue] = useState({
      firstname: '',
      lastname: '',
      email: '',
      phone: '',
      message: ''
    });
    const onChange = (e)=>{
      setValue({...values, [e.target.name]: e.target.value})
    }
    const inputs =[
      {
        id: 1,
        name: "firstname",
        type: "text",
        placeholder: "First Name",
        errorMessage: "please enter first name",
        pattern: "^[A-Za-z0-9]{3,16}$",
        required: true
      },
      {
        id: 2,
        name: "lastname",
        type: "text",
        placeholder: "Last Name",
        errorMessage: "please enter last name",
        pattern: "^[A-Za-z0-9]{3,16}$",
        required: true
      },
      {
        id: 3,
        name: "email",
        type: "text",
        placeholder: "Email",
        errorMessage: "please enter email",
        pattern: "[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$",
        required: true
      },
      {
        id: 4,
        name: "phone",
        type: "number",
        placeholder: "Phone",
        errorMessage: "please enter phone",
        pattern: "[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$",
        required: true
      }
    ]
    const handleSubmit = (e)=>{
      e.preventDefault();
    }
  return (
    <div className="contact__us pt-20">
      <section id="contact">
        <div className="theme__container">
          <div className="meetour__arrow mt-16">
            <img src={long_arrow} alt="" />
          </div>
          <h1
            className="section-header text-6xl "
            data-aos="fade-right"
            data-aos-duration="2000"
          >
          CONTACT US
          </h1>
          <div className="contact-us-wrapper flex items-center">
          <div className="lettalkwrapper">
            <h4 className="theme__mini__heading text-sm sm:text-base skyblue-clr" data-aos="fade-up" data-aos-duration="2000">
              What we’re offering
            </h4>
            <h2 className="font-semibold text-white text-5xl leading-relaxed" data-aos="fade-up" data-aos-duration="2500">
              Let’s talk with
              <br />
              <span className="expertise">[Expert]</span>
            </h2>
            <p className="text-lg text-white-60 mt-8 mb-15 "  data-aos="fade-up" data-aos-duration="3500">
              Contact our team of experts to book a pickup today, call us 24/7,
              email us, or speak to an expert.
            </p>
            <div
              className="direct-contact-container md-mobile-none desktop-block"
              data-aos="fade-left"
              data-aos-duration="2500"
            >
             <ul className="callicon">
                <li className="text-white flex mb-3 call-wrap">
                    <Link  to="tel:+1(832)7572806" className="flex items-center">
                      <img src={call} alt="" className="mr-3" />
                      +1 (832) 757-2806</Link>
                </li>
                <li className="text-white flex mb-3">
                    <Link  to="mailto:umar@uhusolutions.com"  className="flex items-center">
                        <img src={email} alt="" className="mr-3" />
                        umar@uhusolutions.com</Link>
                </li>
                <li className="text-white flex mb-3">
                    <Link  to="/" className="flex items-center">
                        <img src={adress_icon} alt="" className="mr-3" />
                        5650 Roberts Rd, Katy, TX 77494</Link>
                </li>
             </ul>
            </div>
          </div>
          <div className="contact-wrapper">
          <h1 className="section-header text-3xl" data-aos="fade-right" data-aos-duration="2000">Get In Touch</h1>
            <form id="contact-form" className="form-horizontal" onSubmit={handleSubmit}>
            <div className="fields_grid">
              {
                inputs.map(input=>(
                  <ContactInput key={input.id} {...input} value={values[inputs.name]} onChange={onChange}  />
                ))
              }
              </div>
              <textarea
                className="form-control"
                rows="10"
                placeholder="Message"
                name="message"
              ></textarea>
               <div className="action_btns"><button className="theme_btn theme_btnseond fill inline-block text-white cursor-pointer"  type="submit"><span className="flex items-center">Send</span></button></div>
            </form>
          </div>
          <div
              className="direct-contact-container md-mobile-block md-desktop-none"
              data-aos="fade-left"
              data-aos-duration="2500"
            >
             <ul className="callicon">
                <li className="text-white flex mb-3 call-wrap">
                    <Link  to="/" className="flex items-center">
                        <img src={call} alt="" className="mr-3" />
                        +18 - 2222 - 3555</Link>
                </li>
                <li className="text-white flex mb-3">
                    <Link  to="/" className="flex items-center">
                        <img src={email} alt="" className="mr-3" />
                        info@uhusolutions.com</Link>
                </li>
                <li className="text-white flex mb-3">
                    <Link  to="/" className="flex items-center">
                        <img src={adress_icon} alt="" className="mr-3" />
                        Showcase, LLC 123 Silkword </Link>
                </li>
             </ul>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ContactUs;
