import {Link} from 'react-router-dom';
const NotDataFound = () => {
    return(
        <>
            <div className="NotDataFound">
                <div className="shape_wrap">
                    <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                        <path fill="#F2F4F8" d="M36.7,-31.1C50.7,-11.8,67.3,3.7,65,15.2C62.6,26.6,41.3,34,24.2,37.3C7.1,40.6,-5.8,39.8,-17.9,34.8C-30,29.9,-41.2,20.9,-48,6.5C-54.9,-7.9,-57.4,-27.9,-48.7,-46.1C-39.9,-64.2,-20,-80.7,-4.3,-77.3C11.4,-73.9,22.8,-50.5,36.7,-31.1Z" transform="translate(100 100)" />
                    </svg>
                </div>
                <h1>4<lottie-player src="https://lottie.host/6250d501-576a-40a3-86af-8512fe078a3c/0la42bZkC3.json" background="transparent" speed="1" class="my-animation AnimateGlobe absolute right-20 top-16 -z-10" loop autoplay ></lottie-player>4</h1>
                <p>Page Not Found</p>
                <Link to="/" className="theme_btn fill text-white inline-block">Back To Home</Link>
            </div>
        </>
    )
}
export default NotDataFound;