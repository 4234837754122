import React, { useState, useEffect } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import Logo from "../../../assets/images/logo.png";
import DarkLogo from "../../../assets/images/logo-dark.png";
import { ReactComponent as ChevronDown } from "../../../assets/images/chevron-down.svg"

const Header = () => {
	const [isActive, setActive] = useState(false);

	const location = useLocation();
	useEffect(() => {
		if (location.hash) {
			const target = document.querySelector(location.hash);
			target?.scrollIntoView({ behavior: 'smooth' });
			//   setTimeout(() => {
			// 	target.scrollIntoView({
			// 		behavior: "smooth",
			// 		block: "center",
			// 		inline: "center"
			// 	});
			// }, 0);
		}
	}, [location.hash]);

	useEffect(() => {
		if (location.pathname.includes('/terms-of-services') || location.pathname.includes('/privacy-policy') || location.pathname.includes('/ContactUs')) {
			document.body.classList.add('contactdetail');
		} else {
			document.body.classList.remove('contactdetail');
		}
	}, [location.pathname])

	const toggleClass = (_navigate) => {
		setActive(!isActive);
		// toto.current.scrollIntoView({ behavior: "smooth" });
	};
	const [stickyClass, setStickyClass] = useState();
	useEffect(() => {
		window.addEventListener('scroll', stickNavbar);
		return () => {
			window.removeEventListener('scroll', stickNavbar);
		};
	}, []);

	const stickNavbar = () => {
		if (window !== undefined) {
			let windowHeight = window.scrollY;
			windowHeight > 100 ? setStickyClass('fixed top-0 left-0 right-0 z-30') : setStickyClass('');
		}
	};

	return (
		<div className={`site__header absolute left-0 right-0 ${stickyClass}`}>
			<div className="theme__container">
				<div className={`site__header__inner pt-5 cmd:pt-8 flex justify-between ${isActive ? "active" : ""}`}>
					<Link to="/" className="site__logo">
						<img className="light_logo" src={Logo} alt="logo" width="180" />
						<img className="hidden dark_logo" src={DarkLogo} alt="logo" width="180" />
					</Link>
					<div className="navbar_wrapper ml-auto self-center">
						<div className={`hamburger-lines hidden ${isActive ? "active" : ""}`} onClick={toggleClass}>
							<span className="line line1"></span>
							<span className="line line2"></span>
							<span className="line line3"></span>
						</div>
					</div>
					<nav>
						<ul className="flex">
							<li>
								<NavLink to={`/#home`} className="text-lg" onClick={toggleClass} >Home</NavLink>
							</li>
							<li>
								<NavLink to={`/#services`} className="text-lg" onClick={toggleClass} ><span className="pr-4 relative">Services<ChevronDown className="absolute right-0 top-2.5">
								</ChevronDown></span></NavLink>
							</li>
							<li>
								<NavLink to={`/#products`} className="text-lg" onClick={toggleClass} >Products</NavLink>

							</li>
							<li>
								<NavLink to={`/#portfolio`} className="text-lg" onClick={toggleClass} >Portfolio</NavLink>
							</li>
							<li>
								<NavLink to={`/#career`} className="text-lg" onClick={toggleClass} >Career</NavLink>
							</li>
							{/*<li>
						<NavLink to={`/#company`} className="text-lg" onClick={toggleClass} >Company</NavLink>
					</li>*/}
							{/*<li>
								<NavLink to={`/terms-of-services`} className="text-lg" onClick={toggleClass} >Terms of Services</NavLink>
							</li>*/}
							<li>
								<NavLink className="text-lg" to="/ContactUs" >Contact us</NavLink>
							</li>
						</ul>
					</nav>
				</div>
			</div>
		</div>
	)
}

export default Header