import React, { useEffect } from 'react'
import long_arrow from "../../../assets/images/sec_before_style.png";
import { Link } from 'react-router-dom';

const TermServices = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, []);
    return (
        <section className='terms-policy pt-20 mb-10'>
            <div className="theme__container">
                <div className="meetour__arrow mt-16">
                    <img src={long_arrow} alt="" />
                </div>
                <h1
                    className="section-header text-6xl "
                    data-aos="fade-right"
                    data-aos-duration="2000"
                >
                    Terms of Services
                </h1>
                <p className="text-md text-white-60 mb-3">
                    Welcome to UHU Solutions, a platform dedicated to facilitating connections between buyers and sellers of software and services through ratings and reviews. The terms outlined below govern your usage of the platform as provided by UHU Solutions.
                </p>
                <p className="text-md text-white-60 mb-3">
                    In the following document, the terms 'we', 'us', and 'our' refer to UHU Solutions, while 'you' or 'you're' denote users accessing and/or using the platform.
                </p>
                <p className="text-md text-white-60 mb-3">
                    These 'Terms of Services' have been updated as of March 1, 2023. Users are advised to periodically revisit this page to review the most recent 'Terms and Conditions.' We reserve the right, at our sole discretion, to update and modify these 'Terms,' and your continued use of the platform following any changes implies your acceptance and willingness to comply with such revisions.
                </p>
                <p className="text-md text-white-60 mb-3">
                    Before utilizing our services, it is essential to carefully review the specified 'terms and conditions.' These constitute a legal contract governing your use of UHU Solutions’s website and services. By accessing and using UHU Solutions' services, browsing our website, registering on UHU Solutions, contributing to our platform, accessing information via our platform, writing reviews for our platform, participating in surveys, or communicating via email or phone, you (collectively 'you' or 'yours') consent to be bound by these terms. If you do not agree with these terms, you must discontinue using this site.
                </p>
                <p className="text-md text-white-60 mb-3">
                    To be eligible to use UHU Solutions and its affiliated platforms and services, users must be 18 years of age or older. This requirement applies to activities such as registering for an account, accessing our services, writing and sharing reviews, and using our website.
                </p>
                <p className="text-md text-white-60 mb-3">
                    When utilizing our services, users might have (in the future / whenever available) the option to create a UHU Solutions account. In doing so, users bear full responsibility for providing and maintaining accurate, current, and complete information about themselves and, if applicable, the company they are associated with.
                </p>
                <h2 className="font-semibold text-white text-2xl leading-relaxed my-4">User Content:</h2>
                <p className="text-md text-white-60 mb-3">
                    When using our website, users must acknowledge their sole responsibility for all types of user-generated content, including reviews, comments, company profiles, and messages. No user-generated content, regardless of how it is produced or submitted to UHU Solutions, is exempt from this responsibility.
                </p>
                <p className="text-md text-white-60 mb-3">
                    Users are expected to submit accurate and up-to-date content and will be held liable for any damage or issues resulting from false, incomplete, misleading, or defamatory information submitted on our platform. By submitting user-generated content, you agree to indemnify, defend, and hold UHU Solutions harmless against any claims arising from such content.
                </p>
                <p className="text-md text-white-60 mb-3">
                    We reserve the right to distribute, edit, or reject any user-generated content across our website and affiliated channels as we deem appropriate. This includes the right to edit, rephrase, or change information provided by users when submitting user reviews for improved clarity, readability, and presentation. However, our right to edit reviews does not exempt users from their responsibility to provide accurate and quality information. We are not obligated to store or provide copies of your content.
                </p>

                <h2 className="font-semibold text-white text-2xl leading-relaxed my-4">Using User-Submitted Content:</h2>

                <p className="text-md text-white-60 mb-3">
                    You acknowledge that we do not verify or endorse any user content, and the responsible use of such content is solely your responsibility. You must assess any user content published on our site and bear the associated risks.
                </p>
                <p className="text-md text-white-60 mb-3">
                    Any reference to products or services, whether by trade name, trademark, supplier, manufacturer, or otherwise, does not constitute an endorsement or recommendation by UHU Solutions. User-generated content such as reviews and website content like rankings predominantly reflect third-party opinions, and UHU Solutions does not guarantee their quality, accuracy, or reliability. By utilizing our services, you may encounter user content that could potentially be offensive, inaccurate, or objectionable, and UHU Solutions cannot be held liable for it.
                </p>
                <h2 className="font-semibold text-white text-2xl leading-relaxed my-4">UHU Solutions's License to User Content: </h2>

                <p className="text-md text-white-60 mb-3">
                    By submitting content through our services, you grant us an irrevocable, perpetual, worldwide, non-exclusive, royalty-free, transferable license to use, reproduce, modify, reformat, adapt, translate, distribute, publish, create derivative works from, or publicly display user content in whole or in part on any existing or future platform for any purpose we deem suitable. Additionally, we may publicly display advertisements and other information alongside or within user content. Users are not entitled to compensation for these advertisements, and the mode and extent of such advertisements may change without notice.
                </p>
                <h2 className="font-semibold text-white text-2xl leading-relaxed my-4">Using our Website/Service: </h2>

                <p className="text-md text-white-60 mb-3">
                    As users of our website and services, you agree to abide by all applicable laws, including privacy, intellectual property, tax laws, and regulations. You must ensure that all user content you make available on our website, including reviews, company details, trademarks, logos, images, and videos, is true, accurate, and free of third-party encumbrances. Additionally, you agree to comply with all notices sent by UHU Solutions regarding your use of our services.
                </p>
                <p className="text-md text-white-60 mb-3">
                    Our website may enable you to browse and share data related to various technology products and service providers, as well as create reviews and related information. However, as service providers, we are not responsible for your usage of our service. While we endeavor to verify the identities of reviewers, the contents of reviews are solely third-party opinions for which we cannot be held liable. Furthermore, we are not liable for or participants in any engagements between vendors or service providers. Vendors and users, connected through our service, must communicate with each other at their own risk, recognizing UHU Solutions as an unrelated third party.
                </p>
                <p className="text-md text-white-60 mb-3">
                    Feedback: All feedback regarding our services and platform, including but not limited to corrections, updates, or suggestions, will be the property of UHU Solutions. By providing feedback, you hereby assign any rights in such feedback to UHU Solutions, without entailing any payments or compensations to you.
                </p>

                <h2 className="font-semibold text-white text-2xl leading-relaxed my-4">Prohibited Activities: </h2>

                <p className="text-md text-white-60 mb-3">
                    When using our services, you agree not to engage in, or encourage any third party to engage in, the following activities:
                </p>
                <ul className='list-disc pl-4'>
                    <li className='text-white text-base opacity-75'>
                        Transmit, submit, email, or otherwise make available any content that is illegal, unlawful, objectionable, libelous, false, abusive, or discriminatory. This includes information that you do not have the right to make legally available under contractual and trust relationships or any other conditions. Additionally, content must not infringe upon any intellectual, proprietary, or privacy rights, including but not limited to patents, trademarks, copyrights, and publicity rights. Content must not include or resemble unauthorized or unsolicited advertising, spam, junk mail, or chain letters. Furthermore, content must not contain software viruses, worms, or any computer code that could interfere with or harm any computer hardware, software, or telecom equipment of UHU Solutions or any of its users.
                    </li>
                    <li className='text-white text-base opacity-75'>
                        Violate our content guidelines by duplicating, rebranding, licensing, sublicensing, transmitting, broadcasting, displaying, or selling information found on UHU Solutions, excluding user content submitted by you. You must not use, share, repurpose, redistribute, utilize, copy, or adapt any content, data, or information viewed on UHU Solutions to provide any service that is competitive with UHU Solutions, at our sole discretion. Moreover, modifying or removing any trademark or copyright material that appears on the service is prohibited. Providing or receiving compensation for writing or removing reviews or other user-submitted content, except for incentivized reviews that comply with our guidelines, is also prohibited. Posting reviews on products/services that are offered by your current employers or on services/products by direct competitors of your employers or any other product/service having a conflict of interest with you is not allowed.
                    </li>
                    <li className='text-white text-base opacity-75'>
                        Violate our Security Guidelines by downloading, copying, collecting, using, or transferring any information, including but not limited to personal information of users obtained from UHU Solutions, unless explicitly permitted in the 'terms of services' or by UHU Solutions. Overriding or attempting to disturb UHU Solutions’s underlying security structures or performing actions that directly or indirectly disturb the working of our infrastructure is prohibited. Using software, devices, robots, scripts, or other means to scrape, crawl, or index any web page or other portion of our service is also not allowed. Engaging in replicating, mirroring, or simulating the appearance of, or trying to impersonate UHU Solutions or any of its services is prohibited. Attempting to gain unauthorized access to UHU Solutions’s services, user accounts, stored data or information, or UHU Solutions’s network or system via hacking or other means is strictly prohibited.
                    </li>
                    <li className='text-white text-base opacity-75'>
                        You must not engage in activities that harm, threaten, harass, abuse, or try to offend others, or log in with an alias that masks your true identity. Additionally, downloading, saving, recording, processing, transferring, or broadcasting data from other users is prohibited.
                    </li>
                </ul>
                <h2 className="font-semibold text-white text-2xl leading-relaxed my-4">Sponsorships and Incentivized Reviews:</h2>
                <p className="text-md text-white-60 mb-3">
                    Acquiring a sponsorship on our platform provides you with the opportunity to enhance visibility and expand your brand’s outreach. When users visit a directory page, sponsored companies are prominently displayed at the top, identified with a sponsored tag. It's important to note that purchasing a sponsorship does not influence your ranking or position on our Leader’s Matrix, nor does it guarantee specific amounts of traffic, leads, business, or sales. Sponsorships solely impact your placement within our company listings.
                </p>

                <h2 className="font-semibold text-white text-2xl leading-relaxed my-4">Purchase Terms: </h2>
                <p className="text-md text-white-60 mb-3">
                    All transactions made through us, including acquiring sponsored or start-up plans, are considered subscriptions. The terms of your agreement with us, corresponding to the subscribed product, endure for the selected subscription duration. Upon purchasing any of our services, you commit to paying the agreed-upon fee at the time of purchase, along with all applicable fees and taxes. Timely payment is crucial as outlined in your purchase agreement, and failure to do so may result in the termination of your services.
                </p>
                <p className="text-md text-white-60 mb-3">
                    Payment methods accepted include credit/debit cards and bank transfers/ACH-enabled bank accounts from a recognized financial institution. By making a purchase, you consent to be charged a recurring subscription fee, either directly by us or through our designated payment agent, as specified in the invoice. This encompasses all relevant fees, charges, taxes, and additional fees incurred if you opt to upgrade your subscription.
                </p>
                <p className="text-md text-white-60 mb-3">
                    Should you decide to upgrade or downgrade your subscription, you will continue using your current plan until the next billing cycle. After that, your subscription and pricing will be adjusted accordingly. Alterations to subscriptions will maintain the original billing schedule but at the updated price post the nearest billing cycle.
                </p>
                <p className="text-md text-white-60 mb-3">
                    Subscriptions automatically renew on a month-to-month billing cycle. To cancel your subscription, a non-renewal/cancellation request must be submitted to <Link to="mailto:info@uhusolutions.com" className="blue-clr">info@uhusolutions.com</Link> at least five business days before the next billing date. Failure to process payment by your chosen provider or the expiration of your payment method renders you responsible for any outstanding amounts. Neglecting to update payment information or cancel the subscription authorizes us to bill your existing payment provider and terminate your subscription.
                </p>
                <p className="text-md text-white-60 mb-3">
                    Your obligation to pay fees persists for the duration of your subscription or until you cancel. Applicable taxes are determined based on the payment information you provide during purchase. You can suspend or cancel your subscription at any time by contacting <Link to="mailto:info@uhusolutions.com" className="blue-clr">info@uhusolutions.com</Link>.
                </p>
                <p className="text-md text-white-60 mb-3">
                    By making a purchase, you acknowledge UHU Solutions' authority to cancel, terminate, suspend, or restrict your access to services without prior notice or liability, with or without cause (including failure to pay fees). We retain the right to alter, modify, upgrade, add, or reduce the quality, availability, and nature of our services/products at our discretion, without prior notice. We also reserve the right to test, modify, or discontinue any offered products or services, impacting how customers access and pay for our services.
                </p>
                <p className="text-md text-white-60 mb-3">
                    We make no guarantees regarding the outcomes of using our product/service offerings. You acknowledge and agree to be responsible for payments under this agreement, notwithstanding any changes, modifications, or evolution in UHU Solutions' services/products throughout the term.
                </p>

                <h2 className="font-semibold text-white text-2xl leading-relaxed my-4">Disclaimer:</h2>
                <p className="text-md text-white-60 mb-3">
                    The services provided by us and all information contained therein are offered on an 'as is' and 'as available' basis, with all faults and mistakes, without any warranty of any kind, and must be used at your own risk as permitted by applicable law. We do not guarantee the accuracy of information regarding vendors and products on this website and disclaim any liability arising from the same.
                </p>
                <p className="text-md text-white-60 mb-3">
                    UHU Solutions makes no representations regarding the suitability of the information, content, reviews, submissions, products, and services listed on this website, nor does the inclusion of any product, service, or information on our website imply any endorsement or recommendation from us. We hereby disclaim all conditions and warranties regarding services, products, software, information, content, and submissions described on this website, including all conditions and warranties of merchantability, fitness for a particular purpose, title, non-infringement, or accuracy. Reviews and other user-generated content on our service reflect the opinions of users and should not be considered factual statements.
                </p>
                <p className="text-md text-white-60 mb-3">
                    UHU Solutions does not guarantee the uninterrupted functioning of its services. Operations may be affected by system or network failures, updates, maintenance checks, or other unforeseeable events. We disclaim any liability for damages caused by any such malfunction or error.
                </p>
                <p className="text-md text-white-60 mb-3">
                    If you are dissatisfied with or harmed in any way by UHU Solutions or anything related to us, you may close your UHU Solutions account or discontinue working with us after paying your liabilities to us.
                </p>

                <h2 className="font-semibold text-white text-2xl leading-relaxed my-4">Liability Limitation: </h2>
                <p className="text-md text-white-60 mb-3">
                    To the maximum extent permitted by law, UHU Solutions shall not be liable for any direct, indirect, consequential, incidental, or punitive damages caused by, arising out of, or connected to your use of or access to our services or website, even if UHU Solutions has been advised of the possibility of such damages. Your access and usage of our services are entirely at your own risk, and any damage caused thereby is your responsibility.
                </p>

                <h2 className="font-semibold text-white text-2xl leading-relaxed my-4">Intellectual Property Rights, Ownership, and Copyright Policy: </h2>
                <p className="text-md text-white-60 mb-3">
                    UHU Solutions retains ownership of all content contained within our website, encompassing our services, features, graphics, designs, aggregated reviews and ratings, and user content compilation. While users retain ownership of the individual user content they submit, they grant UHU Solutions the right to distribute, display, and advertise such content as we deem appropriate. Our services are safeguarded by copyright and trademark laws in the United States and other foreign jurisdictions. Under this agreement, UHU Solutions exclusively owns all intellectual property rights to its services. Users are prohibited from altering, modifying, obscuring, or removing any trademark, copyright, or other proprietary rights notices embedded within our services. Trademarks, logos, service marks, and product names listed on UHU Solutions are the property of UHU Solutions or its licensors and may not be copied, imitated, or used without explicit prior permission. Additionally, the overall look and feel of our site, including graphics, headers, titles, design elements, scripts, buttons, and the site itself, constitute our trademark/service mark and must not be copied, imitated, or used without prior written consent. Any other company names, product names, registered trademarks, logos, and related details belong to their respective owners. In the event of any infringement, whether concerning our brand or yours, please contact us at <Link to="mailto:info@uhusolutions.com" className="blue-clr">info@uhusolutions.com</Link>, and we will address the matter accordingly. UHU Solutions upholds all intellectual property rights and expects its service users to do the same.
                </p>
                <h2 className="font-semibold text-white text-2xl leading-relaxed my-4">Indemnification: </h2>
                <p className="text-md text-white-60 mb-3">
                    You agree to defend and indemnify UHU Solutions against any, and all, third-party claims, demands, causes of action, recoveries, losses, damages, fines, penalties, liabilities, or expenses of any kind (including reasonable legal or accounting costs) arising from:
                </p>
                <ul className='list-disc pl-4'>
                    <li className='text-white text-base opacity-75'>
                        Your failure to adhere to these terms and conditions;
                    </li>
                    <li className='text-white text-base opacity-75'>
                        Your violation of any law or the rights of a third party; or
                    </li>
                    <li className='text-white text-base opacity-75'>
                        Your use of this website.
                    </li>
                </ul>
                <h2 className="font-semibold text-white text-2xl leading-relaxed my-4">Links to Third-Party Sites: </h2>
                <p className="text-md text-white-60 mb-3">
                    UHU Solutions may provide links to websites operated by parties other than us. These links are provided solely as references for users and do not imply any endorsement of the linked platforms, the material posted on them, or any association with their operators. Your use of any such website is entirely at your own risk and must be done on an "as-is" basis. We are not responsible for any loss, damage, or claim arising directly or indirectly from your use or reliance on any of these third-party websites.
                </p>
                <h2 className="font-semibold text-white text-2xl leading-relaxed my-4"></h2>
                <p className="text-md text-white-60 mb-3">
                    These terms and conditions are governed by the laws of Texas, USA, and apply to your use of UHU Solutions regardless of your country of residence, the location from which you access our site, or your country of origin. Any claims related to or arising from these terms must be exclusively resolved by a state or federal court located in Texas, unless otherwise agreed upon by the parties involved.
                </p>
                <p className="text-md text-white-60 mb-3">
                    These terms, together with our privacy policy, constitute the entire agreement between you and UHU Solutions, superseding any prior or contemporaneous proposals or communications, whether written or oral, that may conflict with or supplement these current terms.
                </p>
                <p className="text-md text-white-60 mb-3">
                    Our failure to take action in response to a breach of these user terms does not waive our right to act in response to that breach or any subsequent similar or other breaches. If any of these terms are deemed invalid or unenforceable under applicable law, the remaining terms remain valid and enforceable, and the invalid or unenforceable term will be replaced with a valid and enforceable term that comes closest to the intention of the original term.
                </p>
            </div>
        </section>
    )
}

export default TermServices