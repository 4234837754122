import React from 'react'
import { Link } from "react-router-dom";
import footerlogo from '../../../assets/images/logo.png'
import FacebookIcon from '../../../assets/images/FacebookIcon.svg'

import twitter from '../../../assets/images/twitter.svg'
import linkedin from '../../../assets/images/linkedin.svg'
import pinterrest from '../../../assets/images/pinterrest.svg'
import youtube from '../../../assets/images/youtube.svg'




const Footer = () => {
  return (
    <div className='footer relative pt-20'>
      <div className="theme__container">
        <div className="footer-logo ">
          <Link to="/">
            <img src={footerlogo} alt="footerlogo" width="200" />
          </Link>
        </div>
        <div className="footerlogo-listing mt-12">
          <ul>
            <li>
              <Link to="/">
                <img src={FacebookIcon} alt="logo" />
              </Link>
            </li>
            <li>
              <Link to="/">
                <img src={twitter} alt="logo" />
              </Link>
            </li>
            <li>
              <Link to="/">
                <img src={linkedin} alt="logo" />
              </Link>
            </li>
            <li>
              <Link to="/">
                <img src={youtube} alt="logo" />
              </Link>
            </li>
            <li>
              <Link to="/">
                <img src={pinterrest} alt="logo" />
              </Link>
            </li>
          </ul>
        </div>
        <div className="career-content text-center mt-14">
          <ul className='flex justify-center'>
            <li className='text-white text-base opacity-75'>
              <Link to="/ContactUs">
                Contact Us
              </Link>
            </li>
            {/* <li className='text-white text-base opacity-75'>
              <a href="#portfolio">
                Portfolio
              </a>
            </li>
            <li className='text-white text-base opacity-75'>
              <a href="#services">
                Services
              </a>
            </li>
            <li className='text-white text-base opacity-75'>
              <a href="#career">
                Career
              </a>
  </li> */}
            <li className='text-white text-base opacity-75'>
              <Link to="/terms-of-services">
                Terms of Services
              </Link>
            </li>
            <li className='text-white text-base opacity-75 companyafter-none mr-0'>
              <Link to="/privacy-policy">
                Privacy Policy
              </Link>
            </li>
            {/*<li className='text-white text-base opacity-75 companyafter-none'>
            <a href="#company">
              Company
            </a>
            </li>*/}
          </ul>
        </div>
      </div>
      <div className="bottom-footer mt-12 text-base font-light"><p>© 2024 <span className="blue-clr">UHU Solutions</span>. All rights reserved</p></div>
    </div>
  )
}

export default Footer