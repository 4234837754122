import {React, useState} from "react";

const ContactInput = (props) =>{
    const {id, onChange,errorMessage, ...inputProps} = props;
    const [focused, setFocused] = useState(false);
    const handleFocus = (e)=>{
        setFocused(true)
    }
    return(
        <>
            <div className="field_wrap">
                <input className="form-control" {...inputProps} onChange={onChange} onBlur={handleFocus} focused={focused.toString()} />
                <p className="error-message">{errorMessage}</p>
            </div> 
        </>
    )
}
export default ContactInput;