import React from 'react';
import { Route, Routes } from 'react-router-dom';

// __ __ Style Libaries/Components __ __ //
import TopBarProgress from 'react-topbar-progress-indicator';
import './assets/css/index.css';
import "./assets/css/theme.scss";
import "./assets/css/responsive.scss";

// __ __ Components/Helpers __ __ //
import Layout from './components/layout/layout.js';
import ContactUs from './components/pages/ContactUs/ContactUs';
import NotDataFound from './components/pages/NotDataFound/NotDataFound.jsx'
import TermServices from './components/pages/TermServices/TermServices.jsx';
import PrivacyPolicy from './components/pages/PrivacyPolicy/PrivacyPolicy.jsx';
const Home = React.lazy(() => import("./components/pages/Home/Home.jsx"));

const App = () => {
	return (
		<React.Suspense fallback={<TopBarProgress />}>
			<Routes>
				{/* Guest routes here */}
				<Route path="/" element={<Layout />}>
					<Route index element={<Home />} />
					{/* Add your open routes here <Route path="example" element={<Component />} /> */}
					<Route path="/ContactUs" element={<ContactUs/>	}/>
					<Route path="/terms-of-services" element={<TermServices />} />
					<Route path="/privacy-policy" element={<PrivacyPolicy />} />
				</Route>
				<Route path="*" element={<NotDataFound />} />
			</Routes>
		</React.Suspense>
	);
}

export default App;
